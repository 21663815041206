<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { HOME_PATH, CASHFLOW_APP_PATH, PLAN_AHEAD_PATH } from '@/router/authenticated';

export default {
  name: 'AppFrameMixin',
  data() {
    return {
      isHomePage: false,
      isTabbedPage: false,
      selectedTabIndex: 0,
      tabs: [
        {
          key: HOME_PATH,
          text: 'החודש שלי',
        },
        {
          key: PLAN_AHEAD_PATH,
          text: 'העתיד שלי',
        },
      ],
    };
  },
  created() {
    this.isHomePage = this.isHomePageRoute(this.$router.currentRoute);
    this.isTabbedPage = this.isTabbedPageRoute(this.$router.currentRoute);
    this.selectedTabIndex = this.getSelectedTab(this.$router.currentRoute);
  },
  methods: {
    isHomePageRoute(route) {
      return _.includes(route.path, CASHFLOW_APP_PATH) || _.includes(route.path, HOME_PATH);
    },
    isTabbedPageRoute(route) {
      return this.cashflowTabsEnabled
        && (_.includes(route.path, CASHFLOW_APP_PATH) || _.includes(route.path, HOME_PATH) || _.includes(route.path, PLAN_AHEAD_PATH));
    },
    getSelectedTab(route) {
      return _.includes(route.path, PLAN_AHEAD_PATH)
        ? _.findIndex(this.tabs, t => t.key === PLAN_AHEAD_PATH)
        : _.findIndex(this.tabs, t => t.key === HOME_PATH);
    },
  },
  computed: {
    ...mapGetters('featureFlags', ['cashflowTabsEnabled']),
    showHamburgerMenu() {
      return this.cashflowTabsEnabled ? this.isTabbedPage : this.isHomePage;
    },
    shouldShowBack() {
      return this.$route.name !== 'CashflowStartDaySettings' && this.$route.name !== 'PaymentPage';
    },
    showHeader() {
      return this.showHamburgerMenu || this.shouldShowBack;
    },
  },
  watch: {
    $route(to, from) {
      this.isHomePage = this.isHomePageRoute(to);
      this.isTabbedPage = this.isTabbedPageRoute(to);
      this.selectedTabIndex = this.getSelectedTab(to);
    },
  },
};

</script>
