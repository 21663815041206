<template>
  <div></div>
</template>

<script>
import Intercom from '@/Intercom';
import DDLogs from '@/DDLogs';
import ConsolidatedApi from '@/api/ConsolidatedApi';
import { mapActions, mapGetters, mapState } from 'vuex';
import Segment from '@/Segment';
import _ from 'lodash';

const RouterState = {
  ob: 'ob',
  tour: 'tour',
  home: 'home',
  signupOfferings: 'offerings',
};

export default {
  name: 'StateRouter',
  async created() {
    const routerState = await ConsolidatedApi.getRouterState();
    await this.fetchUtmParamsFromRoute();
    DDLogs.log('Redirecting path', { customerId: this.customerId, redirectedFrom: this.$route.redirectedFrom, redirectPath: routerState });
    Segment.trackUserGot('sr_entered', {
      ...this.utmParams,
      promotion: this.$route.query.promoCode,
      icrc: this.icrcParam,
      offerId: this.$route.query.offerId,
    });
    if (this.$route.query.showIntercom) {
      Intercom.show();
    }
    const path = RouterState[routerState];
    // eslint-disable-next-line no-unused-vars
    const { showIntercom, ...query } = this.$route.query;
    // If there is an offerId in the query, we override the state and go to signupOfferings
    if (path === RouterState.signupOfferings || !!query.offerId) {
      // Before going into offerings page init the offerings store and check for available offerings
      const queryParamsOfferIds = Array.isArray(query.offerId) ? query.offerId : _.compact([query.offerId]);
      await this.init({ offerIds: queryParamsOfferIds, featureFlagAvailableOfferings: this.offeringsExperiment });
      if (queryParamsOfferIds.length === 0) {
        Segment.trackUserGot('ExperimentAssignment', { experimentId: 'signup_offerings', treatment: JSON.stringify(this.offeringsExperiment) });
      }
      // If there are no available offerings or the user has already joined a 100-days challenge, redirect to onboarding
      if (!this.hasSignupOfferings) {
        await this.updateSignupOfferingStatusChosen(); // No need to show the offerings page again
        return await this.$router.replace({ path: RouterState.ob, query });
      }

      if (queryParamsOfferIds.length === 1) {
        const offerId = queryParamsOfferIds[0];
        const queryWithoutOfferId = _.omit(query, 'offerId');
        return await this.$router.replace({ path: `${RouterState.signupOfferings}/${offerId}`, query: queryWithoutOfferId });
      }

      return await this.$router.replace({ path: RouterState.signupOfferings, query });
    }

    if (routerState === 'home') {
      await this.addCustomerActivity('Home');
    }

    if (!path) {
      DDLogs.error('Invalid router state', routerState);
      return await this.$router.replace({ path: 'home', query });
    }
    return await this.$router.replace({ path, query });
  },
  computed: {
    ...mapState('signup', ['utmParams', 'icrcParam']),
    ...mapState('session', ['customerId']),
    ...mapGetters('featureFlags', ['offeringsExperiment']),
    ...mapGetters('signupOfferings', ['hasSignupOfferings']),
  },
  methods: {
    ...mapActions('signupOfferings', ['updateSignupOfferingStatusChosen', 'init']),
    ...mapActions('session', ['addCustomerActivity']),
    ...mapActions('signup', ['fetchUtmParamsFromRoute']),
  },
};
</script>
