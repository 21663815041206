<template>
  <div class="app-header">
    <page-header v-if="showHeader" class="page-header" :class="{'sticky-header': pageHeaderProps.sticky, 'right-nav': true }"
                 v-bind="pageHeaderProps" :animate-changes="searching" :on-logo-click="isHomePage ? returnToCurrentBudgetDate : undefined">
      <template v-slot:header-wildcard-content>
        <cashflow-search-bar v-if="showSearch" :loading="loadingSearchResults" v-model="searchTerm"
                             ref="searchBar" @startSearch="startSearch" @endSearch="endSearch" />
        <transition name="fade-out">
          <header-wildcard-content v-if="!showSearch || !searching" key="header-wildcard-content"
                                   :class="{ 'indent-header-content' : showSearch, 'animate': !searching }" />
        </transition>
      </template>
    </page-header>
    <general-error v-if="errorType"></general-error>
    <template v-else>
      <content-loader v-show="contentLoading"/>
      <bottom-tabs-page v-if="isTabbedPage" v-show="!contentLoading" hide-on-scroll
                        :tabs="tabs" :selected-tab-index="selectedTabIndex" @tab-click="tabClick">
        <router-view />
      </bottom-tabs-page>
      <template v-else>
        <search-results v-show="showSearch && searching" :search-term="searchTerm"
                        @searchStart="setLoadingSearch(true)"
                        @searchComplete="setLoadingSearch(false)"
                        @searchRestart="onSearchRestart"
                        @resultClicked="resultClicked"/>
        <router-view v-show="!searching && !contentLoading"/>
      </template>
    </template>
  </div>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import ContentLoader from '@/base-components/ContentLoader';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import AppFrameMixin from '@/mixins/AppFrameMixin';
import _ from 'lodash';
import EventBus from '@/event-bus/event-bus';
import GeneralError from '../../pages/general-errors/GeneralError.vue';
import router from '../../router';
import HeaderWildcardContent from './HeaderWildcardContent';
import SearchResults from '../cashflow-search/SearchResults';
import CashflowSearchBar from '../cashflow-search/CashflowSearchBar';

export default {
  name: 'AppHeader',
  beforeRouteUpdate(to, from, next) {
    this.resetError();
    next();
  },
  mixins: [AppFrameMixin],
  data() {
    return {
      initialLoadFinished: false,
      preservedPageHeaderState: null,
      searchTerm: '',
      loadingSearchResults: false,
    };
  },
  components: {
    GeneralError,
    ContentLoader,
    BottomTabsPage: BaseUI.BottomTabsPage,
    PageHeader: BaseUI.PageHeader,
    HeaderWildcardContent,
    SearchResults,
    CashflowSearchBar,
  },
  created() {
    this.resetAppHeaderProps();
  },
  updated() {
    if (this.contentLoading) {
      return;
    }
    if (!this.initialLoadFinished) {
      this.initialLoadFinished = true;
      performanceService.markEnd(`${PerformanceMarks.COMPONENT}_AppHeader`);
      this.$nextTick(() => {
        performanceService.logPerformanceReport();
      });
    }
  },
  methods: {
    ...mapActions('genericError', ['resetError', 'setErrorType']),
    ...mapActions('appHeader', ['setAppHeaderProps', 'resetAppHeaderProps']),
    ...mapActions('cashflowView', ['backfillBudgetDates', 'moveToSpecificBudgetMonth']),
    ...mapActions('cashflowSearch', ['setSearching']),
    tabClick(key) {
      Segment.trackUserInteraction('CashflowApp_TabClicked', { tab: key });
      router.push({ path: `/${key}`, query: this.$route.query });
    },
    startSearch() {
      Segment.trackUserInteraction('CashflowApp_SearchClicked');
      this.preservedPageHeaderState = _.cloneDeep(this.pageHeaderProps);
      this.setAppHeaderProps({
        showLogo: false,
        rightActions: [],
      });
      this.setSearching(true);
    },
    endSearch() {
      this.setAppHeaderProps(this.preservedPageHeaderState);
      this.preservedPageHeaderState = null;
      this.setSearching(false);
      this.searchTerm = '';
      EventBus.$emit('search-closed');
    },
    setLoadingSearch(loading) {
      this.loadingSearchResults = loading;
    },
    async resultClicked(transaction) {
      Segment.trackUserInteraction('CashflowSearch_SearchResultClicked', {
        searchTerm: this.searchTerm, transactionId: transaction.transactionId, budgetDate: transaction.budgetDate,
      });
      this.endSearch();
      const transactionId = transaction.actual?.transactionId ?? transaction.transactionId;
      // lazy load necessary previous cashflows, for availability in the selected budget date's graphs and back navigation
      await this.backfillBudgetDates({ budgetDate: transaction.budgetDate });
      await this.$router.push({
        params: {
          budgetDate: transaction.budgetDate,
        },
        query: {
          ...this.$route.query,
          transactionId,
        },
      });
    },
    onSearchRestart() {
      Segment.trackUserInteraction('CashflowSearch_SearchRestarted', { searchTerm: this.searchTerm });
      this.searchTerm = '';
      this.$refs.searchBar.focus();
    },
    returnToCurrentBudgetDate() {
      Segment.trackUserInteraction('CashflowApp_LogoClicked');
      this.moveToSpecificBudgetMonth({ budgetDate: this.currentBudgetDate });
    },
  },
  computed: {
    ...mapGetters('contentLoader', ['contentLoading']),
    ...mapGetters('appHeader', ['pageHeaderProps']),
    ...mapGetters('featureFlags', ['showCashflowSearch']),
    ...mapGetters('cashflowView', ['currentBudgetDate']),
    ...mapState('genericError', ['errorType']),
    ...mapState('cashflowView', ['cashflowUITree']),
    ...mapState('cashflowSearch', ['searching']),
    showSearch() {
      return this.showCashflowSearch && this.showHamburgerMenu;
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.name !== oldValue.name) {
        this.resetAppHeaderProps();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/global-variables.scss";
$search-icons-size: 24px;
$icons-margin: 16px;

.app-header {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .sticky-header {
    position: sticky;
    top: 0;
  }
  .page-header {
    height: $app-header-height;
  }
  .indent-header-content {
    margin-right: calc($icons-margin + $search-icons-size + $icons-margin);
  }
  .animate {
    &.fade-out-enter, &.fade-out-leave-to {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
}

</style>
