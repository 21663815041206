<template>
  <div class="invite-member-error-page">
    <alert-page :img="require('@/assets/errors/fallen-flower-pot.png')"
                img-style="small"
                :title="'הלינק להצטרפות לתזרים המשותף פג תוקף'"
                :showButton="false">
      <template v-slot:content>
        <span>אם</span>
        <span class="bold">השותף או השותפה שהצטרפו כבר לשירות יפנו אלינו בצ׳אט</span>
        <span>נדאג לשלוח לך לינק עדכני בהקדם.</span>
      </template>
    </alert-page>
  </div>
</template>

<script>

import Segment from '../../Segment';
import AlertPage from '../../base-components/AlertPage.vue';

export default {
  name: 'InviteMemberError',
  components: {
    AlertPage,
  },
  async created() {
    window.scrollTo(0, 0);
    Segment.trackUserGot('InviteMemberErrorEntered');
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/mixins';

  .invite-member-error-page {
    .bold {
      font-weight: bold;
    }
  }
</style>
