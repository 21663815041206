<template>
  <div class="offer-timer">
    <div class="timer-header">ההצעה נגמרת בעוד</div>
    <div class="timer-content">
      <time-limited-offer-timer-counter :end-date="endDate" :color="'green'" v-if="noTrialDiscount"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TimeLimitedOfferTimerCounter from './TimeLimitedOfferTimerCounter';

export default {
  name: 'TimeLimitedOfferTimer',
  components: { TimeLimitedOfferTimerCounter },
  props: {
    endDate: { type: Object, require: true },
  },
  computed: {
    ...mapGetters('timeLimitedOffers', ['noTrialDiscount']),
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.offer-timer {
  font-size: 16px;
  font-family: SimplerPro,sans-serif;
  text-align: center;
  cursor: pointer;
  line-height: 24px;

  .timer-header {
    position: relative;
    height: 30px;
    vertical-align: middle;
    z-index: 1;
    bottom: -15px;
    margin: auto;
    width: max-content;
    font-weight: bold;
    background: $riseup_green;
    border: 1px solid $riseup_green;
    padding: 0 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    line-height: 30px;
    color: $riseup_white;
  }

  .timer-content {
      position: relative;
      height: 88px;
      padding-top: 26px;
      overflow: hidden;
      background: $riseup_light_green;
      border-radius: 10px;
  }
}
</style>
