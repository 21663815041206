<template>
  <div class="header-content">
    <img v-if="show100DaysBanner" src="/images/im-on-it/on-it-header-logo.svg" aria-label="על זה ביחד"/>
    <story-menu-header></story-menu-header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StoryMenuHeader from './StoryMenuHeader';

export default {
  name: 'HeaderWildcardContent',
  components: {
    StoryMenuHeader,
  },
  computed: {
    ...mapGetters('imOnIt', ['show100DaysBanner']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';

.header-content {
  height: $ri-spacing-ml;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
</style>
