<template>
  <search-results-empty-state v-if="!results.length" :search-term="searchTerm" :loading="loading"
                              :on-restart="() => $emit('searchRestart')"/>
  <div v-else data-cy="search-results" class="flex-column align-center">
    <div class="budget-date-search-results" v-for="budgetDate in descendingResultsBudgetDates" :key="budgetDate">
      <div class="padding-xs ri-bold-body">{{ budgetDate | toMonthAndYear }}</div>
      <div class="flex-row" v-for="({ refIndex, item }) in resultsByBudgetDate[budgetDate]" :key="refIndex" tabindex="0" :class="{ loading }"
           @click.stop="loading ? undefined : debouncedResultClick(item)" @keyup.enter.stop="loading ? undefined : debouncedResultClick(item)">
        <div class="search-result-placement-indicator" :class="getClass(item)"/>
        <predicted-transaction v-if="item.isPredictedTransaction"
                               class="results-transaction"
                               :key="item.transactionId"
                               :has-update="item.hasUpdate"
                               :predicted-transaction="item"
                               :can-edit-category="false">
        </predicted-transaction>
        <transaction v-else
                     class="results-transaction"
                     :has-update="item.hasUpdate"
                     :transaction="item"
                     :can-edit-category="false"
                     :key="item.transactionId"/>
      </div>
    </div>
    <div class="margin-ml">
      <action-button v-if="hasMoreBudgetsToLoad" class="margin-bottom-xs" @click="loadMoreBudgets" :loading="loadingMoreBudgets"
                     :show-left-arrow="false" text="טען תוצאות ישנות יותר" :disabled="loadingMoreBudgets" no-icon/>
      <div class="ri-label">(מציג תוצאות מ{{ allCashflows[0].budgetDate | toMonthAndYear }})</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Fuse from 'fuse.js';
// eslint-disable-next-line max-len
import Transaction from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/Transaction';
// eslint-disable-next-line max-len
import PredictedTransaction from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/PredictedTransaction';
import Segment from '@/Segment';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import cashflowViewConsts from '@/constants/cashflow-view';
import SearchResultsEmptyState from './SearchResultsEmptyState';

const SEARCH_KEYS = [
  'amount',
  'businessName',
  'trackingCategoryName',
  'fixedExpenseCategoryName',
  'fuzzyDate',
  'customerComment',
  'sequenceCustomerComment',
  'planName',
  'cardNumber',
  'paymentAppComment',
  'paymentAppAddressee',
];

export default {
  name: 'SearchResults',
  components: {
    SearchResultsEmptyState,
    Transaction,
    PredictedTransaction,
    ActionButton: BaseUI.ActionButton,
  },
  props: {
    searchTerm: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      results: [],
      debouncedSearch: _.debounce(this.search, 1000),
      debouncedResultClick: _.debounce(this.onResultClick, 1000, { leading: true }),
      fuse: null,
      loading: false,
      loadingMoreBudgets: false,
      reachedEndOfResults: false,
    };
  },
  computed: {
    ...mapState('cashflowSearch', ['transactionsForSearch']),
    ...mapState('cashflowView', ['allCashflows']),
    ...mapGetters('editTransaction', ['categoryToVariant']),
    ...mapState('planAhead', ['plans']),
    resultsByBudgetDate() {
      return _.groupBy(this.results, ({ item }) => item.budgetDate);
    },
    descendingResultsBudgetDates() {
      return _.orderBy(_.keys(this.resultsByBudgetDate), null, 'desc');
    },
    hasMoreBudgetsToLoad() {
      return this.allCashflows[0].budgetDate !== cashflowViewConsts.GOOD_CASHFLOWS_START && !this.reachedEndOfResults;
    },
  },
  created() {
    this.initSearch();
  },
  methods: {
    ...mapActions('cashflowView', ['backfillBudgetDates']),
    initSearch() {
      if (!this.transactionsForSearch) {
        return;
      }
      this.fuse = new Fuse(this.transactionsForSearch, {
        keys: SEARCH_KEYS,
        useExtendedSearch: true,
      });
    },
    search(term) {
      if (!this.fuse) {
        this.debouncedSearch(this.searchTerm);
        return;
      }
      performanceService.markStart(PerformanceMarks.CASHFLOW_SEARCH);
      this.results = this.fuse.search(`'${term}`);
      performanceService.markEndAndLog(PerformanceMarks.CASHFLOW_SEARCH);
      Segment.trackUserInteraction('CashflowSearch_SearchTerm', { searchTerm: term, resultsCount: this.results.length });
      this.loading = false;
      this.$emit('searchComplete');
    },
    getClass(transaction) {
      return this.categoryToVariant[transaction.cashflowCategory];
    },
    async onResultClick(item) {
      this.debouncedSearch.cancel();
      performanceService.markStart(PerformanceMarks.CASHFLOW_SEARCH_RESULT_CLICK);
      this.$emit('resultClicked', item);
    },
    async loadMoreBudgets() {
      this.loadingMoreBudgets = true;
      const oldestBudgetDate = this.allCashflows[0].budgetDate;
      await this.backfillBudgetDates({
        budgetDate: this.allCashflows[0].budgetDate,
        monthsBack: 6,
      });
      if (oldestBudgetDate === this.allCashflows[0].budgetDate) {
        this.reachedEndOfResults = true;
      }
      this.search(this.searchTerm);
      this.loadingMoreBudgets = false;
    },
  },
  watch: {
    searchTerm() {
      const searchTermTooShort = _.isEmpty(this.searchTerm) || this.searchTerm.length < 2;
      if (searchTermTooShort) {
        this.results = [];
        this.debouncedSearch.cancel();
        this.loading = false;
        this.$emit('searchComplete');
        return;
      }
      this.loading = true;
      this.$emit('searchStart');
      this.debouncedSearch(this.searchTerm);
    },
    transactionsForSearch() {
      this.initSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@riseupil/base-ui/src/scss/riseup-colors";

$transaction-border: 1px solid $riseup_gray_10;
.budget-date-search-results {
  width: 100%;
  border-bottom: $transaction-border;
}

.search-result-placement-indicator {
  @each $variant, $color in $variant-to-color {
    &.#{$variant} {
      background-color: $color;
      width: 8px;
      border-top: $transaction-border;
    }
  }
}

.results-transaction {
  flex: 1;
  border-top: $transaction-border;
  border-bottom: $transaction-border;
  cursor: pointer;
}

.loading {
  opacity: 0.5;
}
</style>
