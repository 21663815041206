<template>
  <popup :close-action="closeActionClicked" :buttons="buttons" class="ntd-cta-popup border-radius-medium">
    <template v-slot:content>
      <div class="margin-vertical-sm ri-body">הצעה מיוחדת ומוגבלת בזמן</div>
      <div class="ri-large-headline margin-bottom-m">למה לחכות אם אפשר להתחיל לחסוך כבר היום?</div>
      <div class="description ri-large-body">
        במקום חודש התנסות, אפשר לרכוש כבר היום
        <span class="bold">3 חודשי מינוי במחיר מיוחד!</span>
      </div>
      <div class="margin-top-l margin-bottom-m">
        <img src="@/assets/ntd/ntd-popup.svg" class="ntd-img">
      </div>
      <div class="margin-bottom-m">
        <time-limited-offer-timer v-show="noTrialDiscountEndDate" :end-date="noTrialDiscountEndDate"/>
      </div>
      <div class="ri-label margin-vertical-sm">
        <span> בסיום שלושת החודשים, המינוי יתחדש למינוי חודשי רגיל </span>
        <span class="ri-bold-label"> במחיר של 45 ₪ בחודש.</span>
      </div>
      <separator />
      <div class="ri-label">
        <div class="margin-vertical-sm">
          <span>אפשר לבטל את המינוי בכל עת ולקבל החזר יחסי.</span>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div @click="notInterestedButtonClicked" class="not">אל תציגו לי יותר</div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import router from '@/router';
import TimeLimitedOfferTimer
  from '@/pages/responsive-pages/authenticated/offers/time-limited-offers/TimeLimitedOfferTimer.vue';
import * as moment from 'moment';
import NoTrialDiscountMixin from '@/mixins/NoTrialDiscountMixin.vue';

export default {
  name: 'NoTrialDiscountPopup',
  components: {
    TimeLimitedOfferTimer,
    Popup: BaseUI.Popup,
    Separator: BaseUI.Separator,
  },
  mixins: [NoTrialDiscountMixin],
  computed: {
    ...mapGetters('timeLimitedOffers', ['noTrialDiscountEndDate']),
    buttons() {
      return [{
        title: 'למימוש ההטבה',
        action: () => {
          Segment.trackUserInteraction('SubscriptionCtaPopupMbg_SubscribeButtonClicked', {
            trialPeriod: this.trialPeriod,
            hasConsentToTerms: this.termsEnabled,
          });
          Segment.trackUserGotToPixel('MbgPopup_Interested_Clicked');
          this.$emit('close');
          router.push({ path: '/purchase-offerings' });
        },
        variant: 'primary',
        size: 'slim',
      },
      ];
    },
    endDate() {
      return this.noTrialDiscount && moment(this.noTrialDiscount.endDate);
    },
  },
  created() {
    Segment.trackUserInteraction('NTDPopupShown', { trialPeriod: this.trialPeriod });
  },
  methods: {
    ...mapActions('timeLimitedOffers', ['deleteTimeLimitedOffer']),
    closeActionClicked() {
      Segment.trackUserInteraction('NTDPopup_CloseButtonClicked', { });
      this.subscriptionCtaDismissedMbg();
    },
    async notInterestedButtonClicked() {
      Segment.trackUserInteraction('NTDPopup_NotInterestedButtonClicked', { });
      await this.deleteTimeLimitedOffer(this.noTrialDiscountOfferId);
      this.subscriptionCtaDismissedMbg();
    },
    subscriptionCtaDismissedMbg() {
      this.ctaDismissNtd();
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .ntd-cta-popup {
    background-color: $riseup_beige;
    .ntd-img {
      width: 100%;
      height: 100%;
    }
    .not {
      cursor: pointer;
    }
  }
</style>
