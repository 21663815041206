<template>
  <invite-member-error v-if="inviteMemberError"/>
  <server-error v-else></server-error>
</template>

<script>

import { mapState } from 'vuex';
import ServerError from './ServerError.vue';
import InviteMemberError from './InviteMemberError.vue';
import Segment from '../../Segment';

export default {
  created() {
    Segment.trackUserGot('GeneralErrorEntered');
  },
  components: {
    ServerError,
    InviteMemberError,
  },
  name: 'GeneralError',
  computed: {
    ...mapState('genericError', ['errorType', 'ERROR_TYPES']),
    inviteMemberError() {
      return this.errorType === this.ERROR_TYPES.INVITE_MEMBER_ERROR;
    },
  },
};
</script>
