<template>
  <div class="content-loader">
    <lottie-player src="/animations/new-skeleton-loader.json" :autoplay="true"/>
  </div>
</template>

<script>

export default {
  name: 'contentLoader',
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../scss/mixins';

  .content-loader {
    @include responsive-loader-padding;
    padding-top: 20px;
    width: 100%;
  }

</style>
