<template>
  <div v-if="!isLoaded">
    <page-header v-if="showHeader" :right-actions="[]" show-logo bottom-border :logo-color="logoColor" />
    <content-loader />
  </div>
  <router-view v-else />
</template>

<script>
import _ from 'lodash';
import store from '@/store';
import BaseUI from '@riseupil/base-ui';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import ContentLoader from '@/base-components/ContentLoader';
import AppFrameMixin from '@/mixins/AppFrameMixin';
import Intercom from '@/Intercom';
import BenefitsApi from '@/api/BenefitsApi';

const cashflowAppRouteNames = ['Home', 'Demo', 'CashflowApp'];

export default {
  name: 'AuthenticateToken',
  data() {
    return {
      isLoaded: false,
      logoColor: BaseUI.Colors.riseupYellow,
    };
  },
  mixins: [AppFrameMixin],
  components: {
    ContentLoader,
    PageHeader: BaseUI.PageHeader,
  },
  async created() {
    const query = { ...this.$route.query };
    delete query.phoneNumber;
    this.$router.replace({ query });
    await this.initCustomerState();
  },
  methods: {
    async initCustomerState() {
      performanceService.markStart(PerformanceMarks.INIT_CUSTOMER_STATE);
      const initCashflowApp = this.shouldInitCashflowApp();
      await store.dispatch('contentLoader/startContentLoading', 'customerState');
      await store.dispatch('featureFlags/init');
      // We want to start loading insights early on but not wait on them to finish so we don't block the cashflow loading
      // eslint-disable-next-line
      store.dispatch('insights/initInsights');
      await Promise.all(_.compact([
        store.dispatch('session/init', {
          incognito: this.$route.query.incognito,
          name: this.$route.name,
        }),
        initCashflowApp && store.dispatch('cashflowView/initBudgets', { budgetDate: this.$route.params.budgetDate }),
        !initCashflowApp && store.dispatch('cashflowView/initCashflowStartDay'),
        store.dispatch('applicationState/initApplicationState'),
        store.dispatch('benefits/initBenefits'),
        store.dispatch('ambassadorsTouchPoints/init'),
        store.dispatch('credentials/fetchCredentials'),
        store.dispatch('credentials/fetchObkSourceConfigurations'),
        store.dispatch('planAhead/initPlans'),
        store.dispatch('advisory/initAdvisory'),
        store.dispatch('suggestOfferingsPopup/init'),
      ]));
      const benefit = await BenefitsApi.getActiveBenefit();
      // We dont wait for intercom update as it is external service that might be slow
      // and we dont want to delay the UI infavor of it...
      // eslint-disable-next-line
      Intercom.update(this.customerId, this.householdName, { benefits: `[${benefit.promotion}]` });
      await store.dispatch('contentLoader/doneContentLoading', 'customerState');
      this.isLoaded = true;
      performanceService.markEnd(PerformanceMarks.INIT_CUSTOMER_STATE);
    },
    shouldInitCashflowApp() {
      const routeName = this.$router.currentRoute.name;
      return cashflowAppRouteNames.includes(routeName);
    },
  },
};

</script>
