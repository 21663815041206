<template>
  <div class="search-results-empty-state">
    <img v-if="introductionScreen" class="empty-state-image" :src="require('@/assets/search-drawing.png')"/>
    <img v-else class="no-results-image" :src="require('@/assets/no-results-drawing.png')"/>
    <div class="padding-top-m padding-bottom-xs padding-horizontal-ml ri-bold-body">{{ subtitleText }}</div>
    <div class="refresh ri-bold-label" v-if="emptyResults" @click="onRestart" @keyup.enter="onRestart" tabindex="0">
      אולי ננסה חיפוש שונה? <icon icon-name="cached" weight="bold"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'SearchResults',
  components: {
    Icon: BaseUI.Icon,
  },
  props: {
    searchTerm: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    onRestart: {
      type: Function,
      required: true,
    },
  },
  computed: {
    searchTermTooShort() {
      return !this.searchTerm || this.searchTerm.length < 2;
    },
    introductionScreen() {
      return this.loading || this.searchTermTooShort;
    },
    emptyResults() {
      return !this.loading && this.searchTerm?.length > 2;
    },
    subtitleText() {
      if (this.loading) {
        return 'מחפשים עסקאות תואמות...';
      }
      if (this.searchTermTooShort) {
        return 'כאן ניתן לחפש הוצאות והכנסות בתזרים לפי שם עסק, תאריך, סכום והערה.';
      }
      return 'לא נמצאו תוצאות';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.search-results-empty-state {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  flex: 1;
  margin-top: $ri-spacing-xxl;

  .empty-state-image {
    max-width: 230px;
  }
  .no-results-image {
    max-width: 300px;
  }
  .refresh {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    color: $riseup_blue;
    cursor: pointer;
  }
}
</style>
