<template>
  <div class="cashflow-search-bar" :class="{ searching }" tabindex="0">
    <action-icon v-if="!loading" class="search-button" action-type="SEARCH" :disabled="!finishLoadingAllBudgets"
                 @click="searchClicked" :override-icon-props="searchButtonProps"/>
    <spinner v-else-if="searching" :color="spinnerColor" class="spinner" style="--spinner-size: 20px"/>
    <transition-group name="fade-in" tag="div" class="flex-row full-width">
      <input key="search-box" ref="search-box" v-if="searching" class="search-box"
             :value="value" type="text" placeholder="חיפוש בתזרים" @input="searchTerm"/>
      <icon v-if="searching" key="cancel" class="close-search" icon-name="cancel" size="20px" fill :color="cancelColor"
            @click.stop="endSearch" @keyup.enter="endSearch" tabindex="0"/>
    </transition-group>
  </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';
import EventBus from '@/event-bus/event-bus';

export default {
  name: 'CashflowSearchBar',
  components: {
    ActionIcon: BaseUi.ActionIcon,
    Icon: BaseUi.Icon,
    Spinner: BaseUi.Spinner,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      searching: false,
      cancelColor: BaseUi.Colors.riseupGray20,
      spinnerColor: BaseUi.Colors.riseupBlack,
      searchButtonProps: {
        weight: '400',
        opticalSize: 24,
      },
    };
  },
  created() {
    EventBus.$on('search-closed', this.externallyEndSearch);
  },
  mounted() {
    const searchTerm = this.$route.query.q?.trim();
    if (searchTerm && searchTerm.length > 1) {
      this.$nextTick(() => {
        this.searchClicked();
        this.searchTerm({ target: { value: searchTerm } });
      });
    }
  },
  beforeDestroy() {
    this.setSearching(false);
    this.endSearch();
    EventBus.$off('search-closed', this.externallyEndSearch);
  },
  computed: {
    ...mapState('cashflowView', ['finishLoadingAllBudgets']),
  },
  methods: {
    ...mapActions('cashflowSearch', ['setSearching']),
    searchTerm(event) {
      this.$emit('input', event.target.value);
    },
    searchClicked() {
      if (!this.searching) {
        this.searching = true;
        this.$emit('startSearch');
        this.focus();
      } else {
        this.$emit('search', this.$refs['search-box'].value);
      }
    },
    endSearch() {
      this.searching = false;
      this.$router.replace({ query: { q: undefined } });
      this.$nextTick(() => {
        this.$emit('endSearch');
      });
    },
    externallyEndSearch() {
      this.searching = false;
    },
    focus() {
      this.$nextTick(() => {
        this.$refs['search-box'].focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>

$menu-icon-size: 24px;
$menu-icon-spacing: 16px;

.cashflow-search-bar {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  width: calc(100% - ($menu-icon-size + $menu-icon-spacing));
  right: calc($menu-icon-size + $menu-icon-spacing);
  transition: all 0s;
  &.searching {
    width: 100%;
    right: 0;
    transition: all 0.3s;
    transition-delay: 0.2s;
    .search-button {
      cursor: default;
    }
  }
  .spinner {
    height: 20px;
    width: 20px;
    max-height: 20px;
    max-width: 20px;
    margin: 2px;
  }
  .search-box {
    border: none;
    width: 100%;
    font-size: 16px;
    outline: none;
  }
  .fade-in-enter-active {
    transition: opacity 0.3s;
    transition-delay: 0.2s;
  }
  .fade-in-enter, .fade-in-leave-to {
    opacity: 0;
  }
  .close-search {
    cursor: pointer;
  }
}
</style>
