<template>
  <div class="stories-menu-container" v-if="showStoryMenu">
    <div class="circle" v-if="eyo2023Story" @click="goToEoyStoryOrTeaser">
      <img src="/images/nav/story-preview.png"/>
    </div>
    <div class="circle" v-if="showMoneyMoonV2Story" @click="goToMoneyMoon2Story">
      <img src="/images/nav/moneymoonv2.png"/>
    </div>
    <div class="circle" v-if="advisoryStory" @click="goToAdvisoryStory">
      <img src="/images/nav/tomer.png"/>
    </div>
    <div class="circle" v-if="contestStory" @click="goToContestStory">
      <img src="/images/nav/contest-story.png"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import router from '@/router';
import Segment from '@/Segment';
import _ from 'lodash';

export default {
  name: 'NavigationHeader',
  async created() {
    if (!this.showStoryMenu) {
      return;
    }

    if (this.advisoryStory) {
      Segment.trackUserInteraction('AdvisoryStoryButtonShown');
    }

    this.initMoneyMoon2Story();
  },
  data() {
    return {
      showMoneyMoonV2Story: false,
    };
  },
  computed: {
    ...mapGetters('featureFlags', ['isStoryMenuOn', 'advisoryStory', 'eyo2023Story', 'showMoneyMoonV2StoryInCashflowFF', 'contestStory']),
    ...mapGetters('applicationState', ['cashflowTypes']),
    ...mapGetters('subscriptionState', ['subscribed', 'isFree']),
    ...mapGetters('session', ['numberOfMembers']),
    ...mapState('applicationState', ['cashflowState']),
    ...mapState('demographicInfo', ['maritalStatus']),
    showStoryMenu() {
      return this.isStoryMenuOn && this.isCashflowRoute && !this.invalidCashflow;
    },
    isCashflowRoute() {
      return this.$route.name === 'Home';
    },
    invalidCashflow() {
      return _.includes([this.cashflowTypes.OUT_OF_DATE, this.cashflowTypes.BUG, this.cashflowTypes.BLOCKED], this.cashflowState);
    },
    isSubscribedOrFree() {
      return this.subscribed || this.isFree;
    },
  },
  methods: {
    ...mapActions('demographicInfo', ['initDemographicInfo']),
    goToEoyStoryOrTeaser() {
      Segment.trackUserInteraction('StoryMenuClicked', { isStoryOpen: this.isStoryOpen });
      router.push({ path: '/st/eoy2023' });
    },
    goToAdvisoryStory() {
      Segment.trackUserInteraction('AdvisoryStoryClicked');
      router.push({ path: '/st/advisory-v1' });
    },
    goToMoneyMoon2Story() {
      Segment.trackUserInteraction('MoneyMoonV2StoryButton_Clicked');
      router.push({ path: '/st/mmv2' });
    },
    goToContestStory() {
      Segment.trackUserInteraction('ContestStoryButton_Clicked');
      router.push({ path: '/st/contest_story_2' });
    },
    async initMoneyMoon2Story() {
      // Showing the insight if feature flag is open, the customer is free or subscribed, and has at least one of three conditions:
      // 1 - has more than one members, 2 - married,3 - did not fill martial status
      const shouldShow = this.showMoneyMoonV2StoryInCashflowFF
        && this.isSubscribedOrFree
        && (this.numberOfMembers > 1 || await this.isMarriedOrHasNoStatus());
      if (shouldShow) {
        Segment.trackUserInteraction('MoneyMoonV2StoryButton_Shown', {
          numberOfMembers: this.numberOfMembers,
          martialStatus: this.martialStatus,
        });
      }

      this.showMoneyMoonV2Story = shouldShow;
    },
    async isMarriedOrHasNoStatus() {
      await this.initDemographicInfo();
      return this.maritalStatus === 'married' || _.isNil(this.maritalStatus) || this.maritalStatus === '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
$spin_seconds: 10s;

.stories-menu-container {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    color: $riseup-white;
    margin-left: $ri-spacing-xs;

    &::before {
      content: '';
      position: absolute;
      width: 34px;
      height: 34px;
      border: 1px dashed $riseup-blue;

      border-radius: 50%;
      animation: spin $spin_seconds linear infinite;
    }

    img {
      width: 28px;
      height: 28px;
    }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>
