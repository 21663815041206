<template>
  <keep-alive v-if="cashflowTabsEnabled" include="CashflowApp,PlanAheadPage">
    <router-view class="view"></router-view>
  </keep-alive>
  <router-view v-else />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CustomerBenefits from '@/mixins/CustomerBenefits';
import NoTrialDiscountMixin from '@/mixins/NoTrialDiscountMixin.vue';
import NoTrialDiscountPopup from '@/pages/responsive-pages/authenticated/trial/no-trial-discount/NoTrialDiscountPopup.vue';

const AmbassadorsTouchPointPopup = () => import('@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/AmbassadorsTouchPointPopup');
const SubscriptionCtaPopup = () => import('@/pages/responsive-pages/authenticated/trial/SubscriptionCtaPopup');
const NewSubscriptionCtaPopup = () => import('@/pages/responsive-pages/authenticated/trial/NewSubscriptionCtaPopup');
const SuggestOfferingsCtaPopup = () => import('@/pages/responsive-pages/authenticated/trial/SuggestOfferingsCtaPopup.vue');

export default {
  name: 'PoupupManager',
  mixins: [CustomerBenefits, NoTrialDiscountMixin],
  props: {
    hideCta: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    if (this.showNtdPopup) {
      if (!this.ntdDismissed) {
        setTimeout(() => {
          this.openModal({
            component: NoTrialDiscountPopup,
          });
        }, 5000);
      }
    } else if (this.shouldShowCta && !this.hideCta) {
      this.openModal({
        component: this.newSubscriptionCta ? NewSubscriptionCtaPopup : SubscriptionCtaPopup,
      });
    // if suggestOffering ff is on, show the popup when conditions are met
    } else if (this.showSuggestOfferingsPopup) {
      if (this.shouldShowOfferingsSuggestions({ inTrial: this.inTrial, inTrialSince: this.since })) {
        this.openModal({
          component: SuggestOfferingsCtaPopup,
        });
      }
    } else if (await this.shouldShowTrialPromotion({ inTrial: this.inTrial, inTrialSince: this.since })) {
      this.openModal({
        component: AmbassadorsTouchPointPopup,
        props: {
          touchPointText: 'שמחים שבחרת לצמוח איתנו!',
          onCloseCallback: this.dismissTrialPromotion,
        },
      });
    }
  },
  computed: {
    ...mapGetters('subscriptionState', ['shouldShowCta', 'inTrial', 'trialPeriod']),
    ...mapGetters('featureFlags', ['cashflowTabsEnabled', 'newSubscriptionCta', 'showSuggestOfferingsPopup']),
    ...mapState('subscriptionState', ['since']),
    ...mapGetters('suggestOfferingsPopup', ['shouldShowOfferingsSuggestions']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('ambassadorsTouchPoints', ['shouldShowTrialPromotion', 'dismissTrialPromotion']),
  },
};
</script>
